:root {
  --primary-color: #0a2347;
  --primary-color2: #eb4547;
  --secondary-color: #38527e;
  --secondary-color2: #ffffff;
  --surface: #2c4067;
  --error: #b00020;
  --on-primary: #ffffff;
  --on-primary2: #ffffff;
  --grey1: #100909;
  --banner-tile: rgba(44, 64, 103, 0.44);
  --disabled-arrow: #ffffff20;
  --shimmer-bg: #193054;
  --shimmer-color2: #193054;
  --shimmer-color3: #0b2346;
  --shimmer-color4: #193054;
  --tooltip-color: #fff;
  --logout-color: #28446c;
}

[data-theme="dark"] {
  --primary-color: #010101;
  --primary-color2: #eb4547;
  --secondary-color: #38527e;
  --secondary-color2: #ffffff;
  --surface: #2c4067;
  --error: #b00020;
  --on-primary: #fff; //#2c4067
  --on-primary2: #ffffff;
  --grey1: #838383;
  --banner-tile: rgba(255, 255, 255, 0.44);
  --disabled-arrow: #2c406780;
  --shimmer-bg: #eff1f3;
  --shimmer-color2: #eff1f3;
  --shimmer-color3: #e2e2e2;
  --shimmer-color4: #eff1f3;
  --tooltip-color: #fff;
  --logout-color: #333435;
}
