@import "../../styling/scss-variables";

.container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a2347;
  color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-image: url("https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/restrictedScreen/restrictedwebBG.jpg");
}
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary-color2;
  box-sizing: border-box;
  border-radius: 1.5rem;
  padding: 2.6rem 8%;
  min-width: 35%;
  max-width: 40%;
  user-select: none;
  background-color: #0a2347;
}
.locationImg {
  object-fit: contain;
  width: 20rem;
  margin-bottom: 8%;
}
.text {
  font-size: 1.4rem;
  text-align: center;
}

@media only screen and (max-width: $tablet-breakpoint) {
  .container {
    background-image: url("https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/restrictedScreen/restrictedBG.jpg");
  }
  .box {
    padding: 2.6rem 8%;
    max-width: 80%;
  }
}
@media only screen and (max-width: $mobile-breakpoint) {
  .container {
    background-image: url("https://storage.googleapis.com/docflix-d8cab.appspot.com/docflix-app/assets/images/restrictedScreen/restrictedBG.jpg");
  }
  .locationImg {
    width: 15rem;
    margin-bottom: 8%;
  }
  .text {
    font-size: 1.2rem;
  }
}
